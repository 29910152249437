import React, { useState, useEffect } from "react";
import ControlledModal from "../../../shared/modals/ControlledModal";
import NewRequest from "./NewRequest";
import PreviousRequests from "./PreviousRequests";
import {Button, Segment, Tab} from "semantic-ui-react";
import { toast } from "react-toastify";
import {parseDate} from "../../../const/functions";
import {GoogleLogin, googleLogout, GoogleOAuthProvider} from "@react-oauth/google";
import jwt_decode from "jwt-decode";

export default function RequestsModal({defaultOpen = false}) {

    const getInitialState = () => { return { requesterPhoneNumber: "", truckNumber: "", purpose: "", platforms: [], visitorNames: [""] }};

    const [gmailInfo, setGmailInfo] = useState(null);
    const [data, setData] = useState(getInitialState());
    const [requests, setRequests] = useState([]);
    const [error, setError] = useState(null);
    const [allPlatforms, setAllPlatforms] = useState([]);
    const [googleKey, setGoogleKey] = useState("");
    const [googleTokenId, setGoogleTokenId] = useState("");
    const [tabHeight, setTabHeight] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const initScrollContainer = React.createRef();
    
    useEffect(() => {
        callApi("Requests/GetGoogleApiKey")
            .then(info => { setGoogleKey(info.key); });

        setGmailInfo(null);
        callApi("Platforms/GetList")
            .then(list => {
                setAllPlatforms(list.map((x) => ({ key: x.id, value: x.id, text: x.name })));
            });
    }, []);
    
    const onClose = () => {
        setGmailInfo(null);
    };

    const reloadPreviousRequests = (email, tokenId, callback) => {
        callApi("Requests/GetByEmail", {data: email, tokenId: tokenId})
            .then(response => {
                if (response.error) {
                    toast.error(response.error);
                    setError(response.error);
                    return;
                }
                callback && callback();
                setRequests(response);
                setError(null);
            });
    };
    
    const createRequest = () => {
        setIsLoading(true);
        return callApi("Requests/Create", { data: data, tokenId: googleTokenId })
            .then(response => {
                setIsLoading(false);
                
                if (response.error) {
                    setError(response.error);
                    return;
                }

                toast.info(t("Thanks! Visit request sent"));
                reloadPreviousRequests(data.requesterEmail, googleTokenId);
                setError(null);

                // Сбрасываем поля; из предыдущего состояния сохраняем только name и email
                const newState = getInitialState();
                newState.requesterName = data.requesterName;
                newState.requesterEmail = data.requesterEmail;
                setData(newState);
                
                return data;
            });
    };
    
    const deleteRequest = (id) => {
        setIsLoading(true);
        return callApi("Requests/Delete", { data: { id: id, email: data.requesterEmail }, tokenId: googleTokenId })
            .then(response => {
                setIsLoading(false);
                
                if (response.error) {
                    setError(response.error);
                    return;
                }

                reloadPreviousRequests(data.requesterEmail, googleTokenId);
                setError(null);
            });
    };
    
    const handleChange = (e, { name, value }) => {
        setData({...data, [name]: value});
    };

    const handleLogin = (info) => {
        const jwtDecode = jwt_decode(info.credential);
        const email = jwtDecode.email;

        setData({
            ...data,
            requesterName: jwtDecode.name,
            requesterEmail: email
        });

        setGoogleTokenId(info.credential);
        reloadPreviousRequests(email, info.credential, () => {
            setGmailInfo(info);
        });

        setError(null);
    };

    const handleLogout = () => {
        googleLogout();
        setGmailInfo(null);
    };

    const tabChange = () => {
        if (initScrollContainer.current) {
            setTabHeight(`${initScrollContainer.current.getBoundingClientRect().height}px`);
        }
    };

    const isNewRequestValid = () => {
        // Не заполнено одно из полей, 
        if (!gmailInfo || !data.requesterName || !data.startDate || !data.finishDate || !data.purpose || !data.visitorCompanyName) {
            return false;
        }

        // Не задано ни одной платформы при отжатом флажке "Все платформы"
        if (data.platforms != null && data.platforms.length <= 0) {
            return false;
        }

        // Не задано ни одного посетителя
        if (data.visitorNames.length <= 0) {
            return false;
        }

        // Имя одного из посетителей не задано
        if (data.visitorNames.some(value => !value || !value.trim())){
            return false;
        }
        
        // Проверяем, что даты валидны
        let parsedFinishDate = parseDate(data.finishDate);
        let parsedStartDate = parseDate(data.startDate);
        if (!parsedStartDate || !parsedFinishDate) {
            return false;
        }
        
        if (parsedStartDate > parsedFinishDate) {
            return false;
        }
        
        return true;
    };

    return (
        <ControlledModal
            title={t("CreateRequest")}
            buttonClassName="send-request"
            buttonSize="small"
            size="large"
            buttonName={t("CreateRequest")}
            saveButtonName={t("SendButton")}
            saveButtonDisabled={!isNewRequestValid()}
            onClose={onClose}
            onSave={createRequest}
            dontCloseAfterSave
            isConfirmForCloseModal={gmailInfo}
            textForCloseModal={t("You will be logged out. Are you sure to close the window?")}
            textForCloseModalCancelButton={t("close")}
            error={error}
            defaultOpen={defaultOpen}
            isScrolling
            isLoading={isLoading}
            noCancelButton
        >
            {googleKey && <GoogleOAuthProvider
                    clientId={googleKey}
                >
                {!gmailInfo
                    ? <div style={{ textAlign: "center", padding: 60 }}>
                        <p>{t("Please login to Google account")}</p>
                        <div className='jc-c'>
                            <GoogleLogin
                                onSuccess={handleLogin}
                                cookiePolicy="single_host_origin"
                            />
                        </div>
                    </div>
                    : <>
                        <div style={{ textAlign: "right" }}>
                            <Button inverted color='red' onClick={handleLogout}>
                                {t("Logout")}
                            </Button>
                        </div>

                        <Tab onTabChange={tabChange} style={{ marginTop: "-30px" }} panes={[
                            {
                                menuItem: t("requestNewTab"),
                                key: "1",
                                render: () => {
                                    return (
                                        <Tab.Pane>
                                            <div ref={initScrollContainer} className="init-scroll">
                                                <NewRequest
                                                    allPlatforms={allPlatforms}
                                                    request={data}
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </Tab.Pane>);
                                }
                            },
                            {
                                menuItem: t("requestPreviousRequestTab") + ` (${requests.length})`,
                                key: "2",
                                render: () => {
                                    return (<Tab.Pane>
                                        <div style={{height: tabHeight || ""}} className="init-scroll fixed-height">
                                            <Segment color="yellow" textAlign="center" size="big">
                                                {t("requestPreviousRequestsNotification")}
                                            </Segment>

                                            <PreviousRequests
                                                requests={requests}
                                                allPlatforms={allPlatforms}
                                                deleteRequest={deleteRequest}
                                            />
                                        </div>
                                    </Tab.Pane>);
                                }
                            }]}/>
                    </>
                }
            </GoogleOAuthProvider>}
        </ControlledModal>
    );
}

