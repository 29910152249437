import React from "react";
import {
    Dropdown,
    Form,
    Message,
    Popup,
    TextArea,
    Checkbox,
    Grid,
    Button, Transition,

} from "semantic-ui-react";
import CustomFieldsTable from "../../../shared/customFieldsEditor/CustomFieldsTable";
import ControlledModal from "../../../shared/modals/ControlledModal";
import InputRegExp from "../../../shared/columns/number/inputNumber";

export default class ActivityEditModal extends React.Component {
    state = this.initialState;

    get initialState() {
        return {
            modal: false,
            companies: [],
            hide: false,
            formData: {
                name: "",
                customFieldsForVisit: [],
                customFieldsForTask: [],
                customFieldsForOrder: [],
                customFieldsForOrdersModule: [],
                client: "",
                infologEnv: "",
                bjHub: "",
                bjZoek: "",
                singra: "",
                operatorWorkInYard: "",
                createRDVThroughYard: "",
                specificDataRetrieveAtRoundCreation: "",
                avizationThroughYard: "",
                carrierAssignmentThroughYard: "",
                timeslotReservation: "",
                specificDocsForLoading: "",
                specificDocsForUnloading: "",
                distributionRegion: "",
                mysqlPrefix: "",
                reflexEnv: "",
                limitExitTimeAddUnloading: "",
                limitFinishTimeAddUnloading: "",
                limitExitTimeAddLoading: "",
                limitFinishTimeAddLoading: "",
                useMainHandlerLoading : "",
                useMainHandlerUnloading : "",
                carrierWorksInYard : "",
                historyOfConfirmations : "",
                fmkpp : "",
                orderPassInKPPSystem : "",
                externalKPPSendUsTimeOfArrival : "",
                doWeCallDriverBeforeEntrance : "",
                doWeHaveFMExitSecurityPost : "",
                doWeNeedToGetDataOfExitFromExternalKPP: "",
                canExternalKPPSendUsTimeOfDriverExit: "",
                platforms: []
            },
            errorText: ""
        };
    }

    load = () => {
        callApi("Companies/GetAll").then(companies => {
            callApi("Platforms/GetList").then(platforms => {
                callApi("Activities/GetFor", this.props.id).then(data => {
                    this.setState({
                        formData: data,
                        companies: companies.map(c => ({key: c.id, value: c.id, text: c.name})),
                        platforms: platforms.map(c => ({key: c.id, value: c.id, text: c.name})),
                    });
                });
            });
        });
    }

    mapSaveFormCustom = (custom) => {
        return custom.map(i => ({
            fieldMatrixAccessTypes: i.fieldMatrixAccessTypes,
            id: i.id,
            isActive: i.isActive,
            name: i.name,
            type: i.type,
            valuesForSelectTypeAsArray: i.valuesForSelectTypeAsArray
        }));
    }

    save = () => {
        const {formData} = this.state;
        let formDataCustomFieldsNew = {
            customFieldsForVisit: this.mapSaveFormCustom(formData.customFieldsForVisit),
            customFieldsForTask: this.mapSaveFormCustom(formData.customFieldsForTask),
            customFieldsForOrder: this.mapSaveFormCustom(formData.customFieldsForOrder)
        };
        const formDataNew = {...this.state.formData, ...formDataCustomFieldsNew};
        return callApi("Activities/SaveOrCreate", formDataNew)
            .then(data => {
                if (data.error !== "") {
                    this.setState({
                        errorText: data.error
                    });
                }
                return data;
            });
    }

    onChangeCustomFields = (customFields) => {
        this.setState(prevState => {
            return {formData: {...prevState.formData, ...customFields}};
        });
    }

    handleChange = (e, {name, value}) => {
        this.setState((prevState) => {
            return {formData: {...prevState.formData, [name]: value}};
        });
    };

    toggleVisibility = (e, {name, value}) => {
        this.setState((prevState) => {
            return {hide: !prevState.hide};
        });
    };

    onBlurInputmaxCountTaskOnTimeSlot = (e, {value}) => {
        if (value === "") {
            this.setState((prevState) => {
                return {
                    formData: {...prevState.formData, maxCountTaskOnTimeSlot: 0}
                };
            });
        }
    }

    render() {
        return (
            <ControlledModal
                buttonName={this.props.children}
                buttonSize={this.props.size}
                title={this.props.title}
                onOpen={this.load}
                onClose={() => {
                    this.props.onClose();
                    this.setState(this.initialState);
                }}
                error={this.state.errorText}
                saveButtonName={this.props.actionButton}
                onSave={this.save}
                className="edit-create-activity-modal"
            >
                <Form>


                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <Form.Input label={t("Name")} name="name" value={this.state.formData.name}
                                                onChange={this.handleChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>{t("client")}</label>
                                    <Dropdown fluid selection search
                                              name="client"
                                              clearable
                                              value={this.state.formData.client || ""}
                                              options={this.state.companies || []}
                                              onChange={this.handleChange}
                                    />
                                </Form.Field>
                            </Grid.Column>


                        </Grid.Row>
                    </Grid>
                    <Form.Field>
                        <label>{t("platforms")}</label>
                        <Dropdown fluid multiple selection search
                                  name="platforms"
                                  value={this.state.formData.platforms || ""}
                                  options={this.state.platforms || []}
                                  onChange={this.handleChange}
                        />
                    </Form.Field>

                    {/*    <Form.Field>
                        <InputRegExp  label={t('Maximum number of tasks per time slot')}
                                      name="maxCountTaskOnTimeSlot"
                                      value={this.state.formData.maxCountTaskOnTimeSlot || ''}
                                      reg={'^[0-9]*$'}
                                      onBlur={(e) => this.onBlurInputmaxCountTaskOnTimeSlot(e,{value: this.state.formData.maxCountTaskOnTimeSlot})}
                                      onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t('Green corridor')}</label>
                        <Checkbox name="isGreenCorridor" checked={this.state.formData.isGreenCorridor} onChange={(e,{name,checked}) => this.handleChange(e,{name, value:checked})} />
                    </Form.Field>*/}
                </Form>
                {
                    this.props.id === null
                        ? null
                        : <div style={{marginTop: "15px"}}>
                            <CustomFieldsTable
                                customFields={this.state.formData}
                                onChangeCustomFields={this.onChangeCustomFields}
                            />
                        </div>
                }
                <Form>

                    <Message
                        style={{marginTop: "15px"}}
                        success
                        header='Cпециальные поля активности для общего обработчика'
                        content={<Button
                            color='white'
                            size='small'
                            style={{marginTop: "10px", marginBottom: "5px"}}
                            className="ui button"
                            onClick={this.toggleVisibility}>{(this.state.hide == false) ? "Показать" : "Скрыть"}</Button>}
                    />


                    <Transition visible={this.state.hide}
                                duration={200}

                                verticalAlign='middle'>
                        <Grid columns={4}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("InfologEnv (Среда Инфолог)")} name="infologEnv"
                                                    value={this.state.formData.infologEnv || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("BJHub")} name="bjHub"
                                                    value={this.state.formData.bjHub || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("BJZoek")} name="bjZoek"
                                                    value={this.state.formData.bjZoek || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("MysqlPrefix")} name="mysqlPrefix"
                                                    value={this.state.formData.mysqlPrefix || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("Singra")} name="singra"
                                                    value={this.state.formData.singra || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("OperatorWorkInYard")} name="operatorWorkInYard"
                                                    value={this.state.formData.operatorWorkInYard || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>

                                    <Form.Field>
                                        <Form.Input label={t("CreateRDVThroughYard")} name="createRDVThroughYard"
                                                    value={this.state.formData.createRDVThroughYard || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("SpecificDataRetrieveAtRoundCreation")}
                                                    name="specificDataRetrieveAtRoundCreation"
                                                    value={this.state.formData.specificDataRetrieveAtRoundCreation || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("AvizationThroughYard")} name="avizationThroughYard"
                                                    value={this.state.formData.avizationThroughYard || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("CarrierAssignmentThroughYard")}
                                                    name="carrierAssignmentThroughYard"
                                                    value={this.state.formData.carrierAssignmentThroughYard || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("SpecificDocsForLoading")} name="specificDocsForLoading"
                                                    value={this.state.formData.specificDocsForLoading || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>



                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("CanExternalKPPSendUsTimeOfDriverExit")} name="canExternalKPPSendUsTimeOfDriverExit"
                                                    value={this.state.formData.canExternalKPPSendUsTimeOfDriverExit || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("CarrierWorksInYard")} name="carrierWorksInYard"
                                                    value={this.state.formData.carrierWorksInYard || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("HistoryOfConfirmations")}
                                                    name="historyOfConfirmations"
                                                    value={this.state.formData.historyOfConfirmations || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("Fmkpp")} name="fmkpp"
                                                    value={this.state.formData.fmkpp || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("OrderPassInKPPSystem")} name="orderPassInKPPSystem"
                                                    value={this.state.formData.orderPassInKPPSystem || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>


                            </Grid.Row>
                            <Grid.Row>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("ExternalKPPSendUsTimeOfArrival")} name="externalKPPSendUsTimeOfArrival"
                                                    value={this.state.formData.externalKPPSendUsTimeOfArrival || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("DoWeCallDriverBeforeEntrance")}
                                                    name="doWeCallDriverBeforeEntrance"
                                                    value={this.state.formData.doWeCallDriverBeforeEntrance || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("DoWeHaveFMExitSecurityPost")} name="doWeHaveFMExitSecurityPost"
                                                    value={this.state.formData.doWeHaveFMExitSecurityPost || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("DoWeNeedToGetDataOfExitFromExternalKPP")} name="doWeNeedToGetDataOfExitFromExternalKPP"
                                                    value={this.state.formData.doWeNeedToGetDataOfExitFromExternalKPP || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>


                            </Grid.Row>
                            <Grid.Row>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("UseMainHandlerLoading")} name="useMainHandlerLoading"
                                                    value={this.state.formData.useMainHandlerLoading || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("UseMainHandlerUnloading")} name="useMainHandlerUnloading"
                                                    value={this.state.formData.useMainHandlerUnloading || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>


                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("Лимит на окончание разгрузки добавочное время (Разгрузка)(минуты)")} name="limitFinishTimeAddUnloading"
                                                    value={this.state.formData.limitFinishTimeAddUnloading || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("Лимит на убытие добавочное время (Разгрузка)(минуты)")} name="limitExitTimeAddUnloading"
                                                    value={this.state.formData.limitExitTimeAddUnloading || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>




                            </Grid.Row>
                            <Grid.Row>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("ReflexEnv (среда Рефлекс)")} name="reflexEnv"
                                                    value={this.state.formData.reflexEnv || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("Лимит на окончание разгрузки добавочное время (Загрузка)(минуты)")} name="limitFinishTimeAddLoading"
                                                    value={this.state.formData.limitFinishTimeAddLoading || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("Лимит на убытие добавочное время (Загрузка)(минуты)")} name="limitExitTimeAddLoading"
                                                    value={this.state.formData.limitExitTimeAddLoading || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("SpecificDocsForUnloading")} name="specificDocsForUnloading"
                                                    value={this.state.formData.specificDocsForUnloading || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>


                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("Региональная дистрибуция")} name="distributionRegion"
                                                    value={this.state.formData.distributionRegion || ""}
                                                    onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </Transition>
                </Form>
            </ControlledModal>
        );
    }
}
