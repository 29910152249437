import React, {useState} from "react";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";
import {Button, Checkbox, Confirm, Icon, Popup, Table} from "semantic-ui-react";
import AccessToInvitationsModal from "./AccessToInvitationsModal";
import {toast} from "react-toastify";
import GridColumnDispatcher from "../../shared/columns/GridColumnDispatcher";

const AccessToInvitations = ({}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [viewDeleteConfirm, setViewDeleteConfirm] = useState(false);
    const headers = [
        {
            name: "Email",
            type: "Text"
        },
        {
            name: "IsActive",
            type: "State",
            valuesForSelectType: [
                {
                    isActive: true,
                    name: t("Active"),
                    value: "true"
                },
                {
                    isActive: true,
                    name: t("notActive"),
                    value: "false"
                }]
        }
    ];

    const onDelete = (f) => {
        callApi("accessInvitations/delete", selectedItems).then(data => {
            toast.done(t("deleted"));
            setSelectedAll(false);
            setSelectedItems([]);
            f && f();
        }).catch(e => toast.error(e));
    };

    const newModal = (load, clearFilters) => <div>
        <AccessToInvitationsModal
            title={t("AddButton")}
            actionButton={t("Save")}
            id={null}
            onClose={load}
            onSave={() => setSelectedAll(false)}
        >
            <Icon name="plus"/>{` ${t("AddAddress")}`}
        </AccessToInvitationsModal>
        <Popup
            content={t("Delete")}
            position="bottom right"
            trigger={
                <Button
                    className="margin-left-15"
                    onClick={() => setViewDeleteConfirm(true)}
                    icon="trash alternate"
                    disabled={!selectedItems.length}
                />
            }
        />

        <Button
            className="margin-left-5"
            onClick={clearFilters}
            icon="close"
            disabled={!clearFilters}
        >
            {t("reset_filters")}
        </Button>

        <Confirm
            dimmer="blurring"
            open={viewDeleteConfirm}
            onCancel={() => {
                setViewDeleteConfirm(false);
            }}
            onConfirm={() => {
                onDelete(() => {
                    load();
                    setViewDeleteConfirm(false);
                });
            }}
            content={t("Are you sure you want to delete the selected rows?")}
            cancelButton={t("Cancel")}
            confirmButton={t("Yes")}
        />
    </div>;

    const headerRow = (filters, setFilter, sort, setSort) =>
        <>
            <Table.Row>
                <Table.HeaderCell className="hc-checkbox"/>
                {
                    headers.map(h => <Table.HeaderCell>
                        {t(h.name)}
                    </Table.HeaderCell>)
                }
                <Table.HeaderCell/>
            </Table.Row>
            <Table.Row>
                <Table.HeaderCell className="hc-checkbox">
                    <Checkbox
                        checked={selectedAll}
                        onChange={(e, {checked}) => {
                            if (checked) callApi('accessInvitations/GetList', {
                                all: true,
                                filters,
                                sort
                            }).then(d => {
                                setSelectedItems(d.map(v => v.id));
                                setSelectedAll(true);
                            });
                            else {
                                setSelectedItems([]);
                                setSelectedAll(false);
                            }
                        }}/>
                </Table.HeaderCell>
                {
                    headers.map(x => <Table.HeaderCell>
                        <GridColumnDispatcher
                            key={"facet" + x.name}
                            column={x}
                            isFacet
                            onChange={setFilter}
                            filters={filters}
                            sort={sort}
                            setSort={setSort}
                            valuesList={x.valuesForSelectType}
                        />
                    </Table.HeaderCell>)
                }
                <Table.HeaderCell/>
            </Table.Row>
        </>;

    return (
        <LazyLoadDictionary
            apiChangeActivity={"accessInvitations/changeIsActive"}
            apiGetList="accessInvitations/GetList"
            name={t("accessToInvitations")}
            className="container-margin-top-bottom"
            newModal={newModal}
            headerRow={headerRow}
            filtersAndSort
            clearSelectedAll={() => {
                setSelectedAll();
                setSelectedItems([]);
            }}
        >
            {
                (rows = [], load, toggleIsActive) => rows.map((row, index) => (
                    <Table.Row key={index}>
                        <Table.Cell>
                            <Checkbox
                                checked={selectedItems.includes(row.id)}
                                onChange={(e, {checked}) => {
                                    selectedAll && setSelectedAll(false);
                                    setSelectedItems(checked ? [...selectedItems, row.id] : selectedItems.filter(i => i !== row.id));
                                }}/>
                        </Table.Cell>
                        <Table.Cell>
                            {row.email}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <Checkbox toggle itemID={row.id} checked={row.isActive} onChange={toggleIsActive}/>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <AccessToInvitationsModal
                                title={t("Change")}
                                actionButton={t("Save")}
                                id={row.id}
                                onClose={load}
                            >
                                <Icon name="edit"/>{` ${t("Edit")}`}
                            </AccessToInvitationsModal>
                        </Table.Cell>
                    </Table.Row>
                ))
            }
        </LazyLoadDictionary>
    );
};

export default AccessToInvitations;