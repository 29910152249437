import React from "react";
import TextEdit from "../../../../shared/columns/text/Edit";
import DriverFormModal from "../../../drivers/components/DriverEditModal";
import {Grid, Icon, Segment, TextArea} from "semantic-ui-react";
import DocumentListViewCompact from "../../../../shared/documents/DocumentListViewCompact";
import SecurityStateEdit from "./SecurityStateEdit";
import Edit from "../../../../shared/columns/textarea/Edit";


export default function DriverInfo({driverInfo, isView, driverId, onSaveDriver, driverState, isStateEdit, handleChangeDriverState, formData}) {
    return (<Segment>
        <Grid columns="equal" className="margin-bottom-10">
            <Grid.Row columns="equal">
                <Grid.Column width={4}>
                    <TextEdit
                        isDisabled
                        name={`${t('Last name')} *`}
                        value={driverInfo.lastName}
                    />
                </Grid.Column>
                <Grid.Column width={4}>
                    <TextEdit
                        isDisabled
                        name={`${t('First name')} *`}
                        value={driverInfo.firstName}
                    />
                </Grid.Column>
                <Grid.Column width={5}>
                    <TextEdit
                        isDisabled
                        name={t('Patronymic')}
                        value={driverInfo.middleName}
                    />
                </Grid.Column>
                <Grid.Column>
                    {!isView && driverId && (
                        <div className="small-edit-btn-block">
                            <DriverFormModal
                                isNotHistory
                                id={driverId}
                                onSave={onSaveDriver}
                            >
                                <Icon name="edit"/>
                            </DriverFormModal>
                        </div>
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row
                columns="equal"
                className="padding-bottom-0 padding-top-0"
            >
                <Grid.Column>{t('Passport details')}</Grid.Column>
            </Grid.Row>
            <Grid.Row
                columns="equal"
                className="padding-bottom-0"
            >
                <Grid.Column>
                    <TextEdit
                        isDisabled
                        name={`${t('SeriesAndNumber')} *`}
                        width={6}
                        value={driverInfo.passportSeriesAndNumber}
                    />
                </Grid.Column>

                {/* <Grid.Column>
                    <div className="ui column grid">pictures</div>
                </Grid.Column>
                <Grid.Column>
                    <div className="ui column grid">pictures</div>
                </Grid.Column>*/}
            </Grid.Row>
            <Grid.Row
                columns="equal"
                className="padding-bottom-0"
            >
                <Grid.Column>{t('WU data')}</Grid.Column>
            </Grid.Row>
            <Grid.Row
                columns="equal"
                className="padding-bottom-0"
            >
                <Grid.Column>
                    <TextEdit
                        isDisabled
                        name={t('WU series and number')}
                        width={6}
                        value={driverInfo.driverLicenseSeries}
                    />
                </Grid.Column>
                <Grid.Column>
                    <TextEdit
                        isDisabled
                        name={t('Expiration Date WU')}
                        width={10}
                        value={driverInfo.driverLicenseValidDate}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row
                columns="equal"
                className="padding-bottom-0"
            >
                <Grid.Column>
                    <TextEdit
                        isDisabled
                        name={`${t('Phone')} *`}
                        value={driverInfo.phone}
                    />
                </Grid.Column>
                <Grid.Column>
                    {/* <TextEdit isDisabled name='Телефон *' value={driverInfo.phoneNumber} onChange={handleChange}/>*/}
                    <SecurityStateEdit
                        name={t('securityStateResult')}
                        value={driverState}
                        isEdit={isStateEdit}
                        isView={isView}
                        onChange={(e, {name, value, parameter}) =>  handleChangeDriverState(value, parameter)}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row
                columns="equal"
                className="padding-bottom-0"
            >
                <Grid.Column className="driver-info__document" width={8}>
                    <DocumentListViewCompact documents={driverInfo.documents || []}/>
                </Grid.Column>
                <Grid.Column className="driver-info__document" width={8}>
                    <Edit
                        isDisabled
                        name={t('DriverNotes')}
                        value={formData.driverNotes}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>);
}
