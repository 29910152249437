import React, {useContext} from "react";
import {Grid, Icon, Form, Segment} from "semantic-ui-react";
import SelectEdit from "../../../../shared/columns/select/Edit";
import CompanyEditModal from "../../../companies/components/CompanyEditModal";
import TransportFormModal from "../../../transports/components/TransportEditModal";
import {UserPermissionsContext} from "../../../../Contexts";
import DriverFormModal from "../../../drivers/components/DriverEditModal";
import TrailerEdit from "../../../../shared/columns/transport/trailer/Edit"
import TruckEdit from "../../../../shared/columns/transport/truck/Edit"
import TruckTypeEdit from "./TruckTypeEdit"
import TrailerTypeEdit from "../../../../shared/columns/transport/truck/Edit"
import DriverEdit from "../../../../shared/columns/driver/Edit";
import DocumentListViewCompact from "../../../../shared/documents/DocumentListViewCompact";
import DocumentListEdit from "../../../../shared/documents/DocumentListEdit";
import SecurityStateEdit from "./SecurityStateEdit";
import Edit from "../../../../shared/columns/textarea/Edit";


export default function TransportInfo({isView, documentTruckInfo, documentTrailerInfo, formData, isStateEdit, transportList = [],truckList = [], trailerList =[], handleChange, loadFields, onSaveTransport}) {
    const {permissionsData} = useContext(UserPermissionsContext);
    const getClassNameTransportInfoDocument = (doc = []) => {
        const cls = []
        if (!!doc.length) {
            cls.push('transport-info-document')
        }
        return cls.join(' ')
    }

    return (<>
            <Form.Field className="margin-bottom-0">
                <label className="margin-bottom-0">{t('truck')}</label>
            </Form.Field>
            <Segment>
                <Grid className="margin-bottom-10">
                    <Grid.Row className="padding-bottom-0 transport-info">
                        <Grid.Column width={5} className="transport-info-item">
                            <TruckEdit
                                name={t('truck')}
                                isNotHistory
                                isCase
                                value={formData.truckId}
                                isDisabled={isView}
                                isEdit
                                valuesList={truckList}
                                reloadFields={onSaveTransport}
                                onChange={(e, {name, value, parameter}) =>  handleChange("truckId", value, parameter)}
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <TruckTypeEdit
                                name={t('truckType')}
                                value={formData.truckTypeId}
                                isDisabled={isView}
                                onChange={(e, {name, value, parameter}) =>  handleChange("truckTypeId", value, parameter)}
                            />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <SecurityStateEdit
                                name={t('securityStateResult')}
                                value={formData.truckState}
                                isEdit={isStateEdit}
                                isView={isView}
                                onChange={(e, {name, value, parameter}) =>  handleChange("truckState", value, parameter)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="padding-bottom-0 transport-info">
                        <Grid.Column width={8} className={getClassNameTransportInfoDocument(documentTruckInfo)}>
                            <DocumentListViewCompact documents={documentTruckInfo || []}/>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Edit
                                isDisabled
                                name={t('TruckNotes')}
                                value={formData.truckNotes}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Form.Field className="margin-bottom-0">
                <label className="margin-bottom-0">{t('trailer')}</label>
            </Form.Field>
            <Segment>
                <Grid className="margin-bottom-10">
                    <Grid.Row className="padding-bottom-0 transport-info">
                        <Grid.Column width={11} className="transport-info-item">
                            <TrailerEdit
                                name={t('trailer')}
                                isNotHistory
                                isCase
                                value={formData.trailerId}
                                isEdit
                                isDisabled={isView}
                                valuesList={trailerList}
                                reloadFields={onSaveTransport}
                                onChange={(e, {name, value, parameter}) =>  handleChange("trailerId", value, parameter)}
                            />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <SecurityStateEdit
                                name={t('securityStateResult')}
                                value={formData.trailerState}
                                isEdit={isStateEdit}
                                isView={isView}
                                onChange={(e, {name, value, parameter}) =>  handleChange("trailerState", value, parameter)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8} className={getClassNameTransportInfoDocument(documentTrailerInfo)}>
                            <DocumentListViewCompact documents={documentTrailerInfo || []}/>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Edit
                                isDisabled
                                name={t('TrailerNotes')}
                                value={formData.trailerNotes}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </>
    );
}
